.top-navigation {
  &.MuiAppBar-root {
    z-index: 1033;
  }

  .custom-search {
    min-width: 500px;
  }

  .custom-search > div {
    border-radius: 0;
    border-color: #eee;
  }

  .custom-search .css-1okebmr-indicatorSeparator {
    display: none;
  }
}

.search-option {
  color: #000 !important;
}

.top-bar-logo {
  height: 22px;
}

.upload-icon {
  color: #5f5f5f;
  margin-right: 4px;
}
