.language-dropdown .flag {
    width: 20px;
    margin-right: 7px;
}

.language-button {
    background: none !important;
    border: none !important;
    padding: 3px !important;
    margin: 0px;
    border-radius: 38px !important;
}

.language-button:hover {
    box-shadow: none !important;
    background: #80808024 !important;
}

.language-button img {
    height: 38px;
    width: 38px;
    padding: 7px;
}