
.item-text {
    margin-left: 10px;
}

.user-button {
    background: none !important;
    border: none !important;
    padding: 3px !important;
    margin: 0px;
    border-radius: 38px !important;
}

.user-button:hover {
    box-shadow: none !important;
    background: #80808024 !important;
}

.user-button img {
    height: 38px !important;
}