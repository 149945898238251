.mui-base-modal {
  width: 85vw;
  max-width: 1000px;
  height: 80vh;
  max-height: 600px;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  position: absolute;
  padding: 20px;
}

.close-mui-base-modal-button {
  position: absolute !important;
  top: 10px;
  right: 10px;
  z-index: 999999 !important;
}

.mui-base-modal-paper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
}


.mui-base-modal-header {
  height: 80px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: lightgray;
  align-items: center;
  display: flex;
  padding-left: 20px;
  background-color: rgba(163, 163, 163, 0.1);
}

.mui-base-modal-content {
  overflow: scroll;
  padding: 20px 50px;
}

.mui-base-modal-footer {
  height: 60px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));
}

