.modal-90w {
    max-width: 70% !important;
}

.modal-100w {
    max-width: 95% !important;
}

.modal-checkbox-labels {
    color: #5a6169
}

.reject-modal-checkboxes label {
    margin: 0;
    fontSize: 11px
}

.reject-modal-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.custom-radio {
    text-align: left;
}

.text-muted {
    justify-content: center;
}