.main-wrapper {
    padding: 0.5rem;
}

.impersonation-row {
    justify-content: center;
    margin-top: 20px;
}

.impersonation-button {
    background: none !important;
    border: none !important;
    padding: 3px !important;
    margin: 0px;
    border-radius: 38px !important;
}

.impersonation-button:hover {
    box-shadow: none !important;
    background: #80808024 !important;
}

.impersonation-button img {
    height: 38px;
}

div > h5 {
    font-weight: 700;
}

.stop-button {
    width: 30%;
}

.impersonation-text {
    font-weight: 700;
}

.icon {
    max-width: 40px;
}

.select-wrapper {
    width: 75%
}

.impersonation-modal h2 {
    font-size: 34px;
    font-weight: 700;
}