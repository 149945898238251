@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$sidebar-bg-color: #ffffff !important;
$sidebar-color: #626c79 !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #00acc1 !default;
$submenu-bg-color: #ececec !default;
$submenu-bg-color-collapsed: #d2d2d2 !default;
$icon-bg-color: rgba(43, 43, 43, 0) !default;
$icon-size: 40px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 960px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;


@import '~react-pro-sidebar/dist/scss/styles.scss';
body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: 'Roboto', sans-serif;
}

#root {
  height: 100%;
}

.pro-sidebar {
  position: fixed;
  min-width: 337px;
}

.app {
  height: calc(100% - 70px);
  display: flex;
  position: relative;
  top: 70px;
}





